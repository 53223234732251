import React from 'react';
import { CSVLink } from 'react-csv';
import { FileExcelTwoTone } from '@ant-design/icons';
import { colors } from '~/options/general';
import { types } from '~/components/general/Table/options';

class Export extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      newDataSource: [],
    };
  }

  getHeaders = () => {
    const { columns } = this.props;
    const headers = [];

    if (columns) {
      columns.forEach(element => {
        if (element.dataIndex && element.exporter !== false) {
          headers.push({ label: element.title, key: element.dataIndex });
        }
      });
    }

    return headers;
  };

  getData = () => {
    const { dataSource, selectedSorter, columns } = this.props;

    let newDataSource = JSON.parse(JSON.stringify(dataSource), (key, value) => {
      const column = columns.find(item => item.dataIndex === key);
      const render =
        column && column.type && types[column.type].render
          ? types[column.type].render
          : typeof value === 'string' &&
            (parseFloat(value || 0) > 0 || value === '0.00') &&
            (value || 0).indexOf('.') > 0 &&
            (value || 0).split('.').length === 2
          ? types.currency.render
          : undefined;
      return render ? render(value) : value;
    });

    if (selectedSorter && selectedSorter.column) {
      newDataSource = dataSource.sort(selectedSorter.column.sort);
      if (selectedSorter.order === 'descend') {
        newDataSource.reverse();
      }
    }
    this.setState({ newDataSource });
  };

  render() {
    const { newDataSource } = this.state;
    const { className } = this.props;

    return (
      <CSVLink separator=";" data={newDataSource} filename={`${new Date().toISOString()}.csv`} headers={this.getHeaders()} onClick={this.getData}>
        <FileExcelTwoTone twoToneColor={colors.polarGreen.color} title=".csv" disabled={newDataSource.length === 0} className={className} />
      </CSVLink>
    );
  }
}

export default Export;
